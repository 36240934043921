import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Stack,
  Drawer,
  Tooltip,
  Typography,
  CardActionArea,
  TextField,
  Menu,
  Button,
  MenuItem
} from '@mui/material';
import Block from 'components/Block';
import { dispatch } from 'store/store';
import { setCurrentBranch } from 'store/slices/auth';
import { setAudioState } from 'store/slices/orders';
import useAuth, { useAuthUser } from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { PATH_DASHBOARD } from '../../routes/paths';
import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import sidebarConfig from './SidebarConfig';
import { LogoIcon } from '../../assets';

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 1.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

type IconCollapseProps = {
  onToggleCollapse: VoidFunction;
  collapseClick: boolean;
};

function IconCollapse({ onToggleCollapse, collapseClick }: IconCollapseProps) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { user, branches, currentBranch } = useAuthUser();
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <Stack
          spacing={3}
          sx={{
            px: 2.5,
            pt: 3,
            pb: 2,
            ...(isCollapse && {
              alignItems: 'center'
            })
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {isCollapse ? (
              <Box component={RouterLink} to={PATH_DASHBOARD.general.orders} sx={{ display: 'inline-flex' }}>
                <img src="/static/smallIcon.png" alt="" width="50" />
              </Box>
            ) : (
              <Box component={RouterLink} to={PATH_DASHBOARD.general.orders} sx={{ display: 'inline-flex' }}>
                <Logo />
              </Box>
            )}

            <MHidden width="lgDown">
              {!isCollapse && (
                <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
              )}
            </MHidden>
          </Stack>

          {isCollapse ? (
            <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
          ) : (
            <AccountStyle>
              <Button
                disabled
                variant="outlined"
                // onClick={handleOpen}
                style={{ width: '100%', border: '1px solid #dedede', color: '#333' }}
              >
                {localStorage.getItem('i18nextLng') === 'ar'? currentBranch?.name?.ar : currentBranch?.name?.en}
              </Button>
            </AccountStyle>
          )}
        </Stack>

        <NavSection navConfig={sidebarConfig} isShow={!isCollapse} onClick={() => setAudioState(false)}/>

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
      {!isCollapse && (
        <Stack
          spacing={3}
          alignItems="center"
          sx={{ mt: 10, width: 1, lineHeight: 0, textAlign: 'center' }}
        >
          <LogoIcon />
        </Stack>
      )}
    </>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

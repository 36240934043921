import { ReactNode, createContext } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import palette from '../theme/palette';
import { ThemeDirection, SettingsContextProps } from '../@types/settings';

export const yellowColor = {
  name: 'yellow',
  lighter: '#FFFF00',
  light: '#FBF701',
  main: '#F3E804',
  dark: '#EBD907',
  darker: '#E7D108',
  contrastText: palette.light.grey[800]
};

const initialState: SettingsContextProps = {
  themeDirection: 'rtl',
  onChangeDirection: () => {}
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
  children: ReactNode;
};

function SettingsProvider({ children }: SettingsProviderProps) {
  const [settings, setSettings] = useLocalStorage('settings', {
    themeDirection: initialState.themeDirection
  });

  const onChangeDirection = (langDirection: string) => {
    setSettings({
      ...settings,
      themeDirection: langDirection as ThemeDirection
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        onChangeDirection
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };

import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        }
      ]
    },

    { path: '/', element: <Navigate to="/auth/login" replace /> },
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // { path: 'app', element: <DashboardStats /> },
        { path: 'menu', element: <MenuView /> },
        // { path: 'menu/view', element: <MenuManagement /> },
        { path: 'menu/check', element: <MenuAvailability /> },
        { path: 'menu/:sectionId/:itemId', element: <EditItem /> },
        { path: 'menu/:sectionId', element: <EditItem /> },
        { path: 'item/:id', element: <CreateNewItem /> },
        { path: 'orders-overview', element: <OrdersOverView /> },
        { path: 'branch', element: <BranchStatus /> },
        { path: 'order-status', element: <OrdersPipeline /> },
        { path: 'orders-history', element: <OrdersList /> },
        // { path: 'performance', element: <Performance /> },
        // { path: 'reports', element: <Reports /> },
        { path: 'opening-times', element: <OpeningTimes /> },
        {
          path: 'user',
          children: [
            { path: 'profile', element: <Navigate to="/dashboard/user/profile" replace /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// auth
const Login = Loadable(lazy(() => import('../pages/auth/login')));
// Restaurant
const MenuView = Loadable(lazy(() => import('../pages/menu')));
const MenuManagement = Loadable(lazy(() => import('../pages/menu/menuManagement')));
const MenuAvailability = Loadable(lazy(() => import('../pages/menu/menuAvailability')));
const CreateNewItem = Loadable(lazy(() => import('../pages/menu/CreateNewItem')));
const EditItem = Loadable(lazy(() => import('../pages/menu/menuManagement')));
const OrdersList = Loadable(lazy(() => import('../pages/orders/ordersList')));
const OrdersOverView = Loadable(lazy(() => import('../pages/orders/orders')));
const Performance = Loadable(lazy(() => import('../pages/performance')));
const OrdersPipeline = Loadable(lazy(() => import('../pages/orders/orders')));
const Reports = Loadable(lazy(() => import('../pages/reports')));
const OpeningTimes = Loadable(lazy(() => import('../pages/openingTimes')));
const BranchStatus = Loadable(lazy(() => import('../pages/branchStatus')));
const DashboardStats = Loadable(lazy(() => import('../pages/dashboardStats')));
// Errors
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

import { useContext, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { onMessageListener } from 'firebase';
import ReactAudioPlayer from 'react-audio-player';
import {  SocketContext } from 'contexts/SocketProvider';

import { styled, useTheme } from '@mui/material/styles';
import { useAuthUser } from 'hooks/useAuth';
import {
  setAudioState,
  getNewOrders,
} from 'store/slices/orders';
import { dispatch, RootState, useSelector } from 'store/store';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { Events, useEvent } from '../../hooks/useEvent';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  // paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default function DashboardLayout() {
  const theme = useTheme();
  const { socket } = useContext(SocketContext);
  const { currentBranch } = useAuthUser();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  
  const audioRef = useRef<ReactAudioPlayer>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    socket?.on('connect', () => {
      console.log('connected from client, branch:', currentBranch);
    });
    socket?.emit('join-room', { room: currentBranch?._id, clientData: {} });
  }, []);
  
  useEffect(() => {
    socket.on(`NEW_ORDER:${currentBranch?._id}`, (data: any) => {
      dispatch(getNewOrders());
      enqueueSnackbar('طلب جديد', { variant: 'success' })
      useEvent.manager.emit(Events.Audio.Play);
    });
  }, []);

  useEvent(Events.Audio.Play, () => {
    audioRef.current?.audioEl?.current?.pause?.();
    audioRef.current?.audioEl?.current?.load?.();
    audioRef.current?.audioEl?.current?.play?.();
  });

  useEvent(Events.Audio.Pause, () => {
    audioRef.current?.audioEl?.current?.pause?.();
  });

  return (
    <RootStyle>
      <div
        style={{
          position: 'relative',
          background: '#fff',
          zIndex: -10000000,
          width: '100%',
          padding: '10px',
          display: 'none'
        }}
      >
        <ReactAudioPlayer
          style={{ position: 'absolute', background: '#fff', display: 'unset' }}
          src="/sound/sound.mp3"
          ref={audioRef}
          autoPlay={false}
          loop={true}
          controls
        />
      </div>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}

import useAuth, { useAuthUser } from '../hooks/useAuth';
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user }: { user: any } = useAuthUser();
  return (
    <MAvatar
      src={user?.branchId?.logo}
      alt={user?.name}
      color={user?.branchId?.logo ? 'default' : createAvatar(user.name).color}
      {...other}
    >
      {createAvatar(user.name).name}
    </MAvatar>
  );
}

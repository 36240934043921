import { EventEmitter } from 'events';
import { useEffect } from 'react';

export const Events = {
    Audio: {
        Play: 'audio:play',
        Pause: 'audio:pause'
    }
};

export const eventsManager = new EventEmitter();

export const useEvent = (eventName: string, handler: () => void) => {
  useEffect(() => {
    eventsManager.on(eventName, handler);
    return () => {
        eventsManager.off(eventName, handler);
    }
  }, []);
};

useEvent.manager = eventsManager;
import React, { Children, ReactChildren, ReactNode, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
// import { AnyAction } from 'redux';
// import { ReactReduxContextValue } from 'react-redux';
// import { setting } from './constants';
import { baseURL } from '../config';
// import {getData, storeData} from '../utils/storage';

// type socketContextProps = {
//   cid: string;
//   socket: any;
// };

// const SocketContext = React.createContext<socketContextProps | null>(null);

// export function useSocket() {
//   const socketContextHook = useContext(SocketContext);
//   if (!socketContextHook) throw new Error('no context provided');
//   return socketContextHook;
// }

// const SocketProvider = ({ children }: childrenProp) => {
//   const [cid, setCid] = useState<string>('');
//   const [socket, setSocket] = useState<any>();

//   useEffect(() => {
//     const newSocket: any = io(setting.SOCKET_ENDPOINT, {
//       reconnection: true,
//       reconnectionDelay: 500,
//       transports: ['websocket']
//     });
//     setSocket(newSocket);
//     console.log('newSocket', newSocket);

//     return () => newSocket.close();
//   }, [baseURL.socketEndpoint, cid]);

//   return <SocketContext.Provider value={{ socket, cid }}>{children}</SocketContext.Provider>;
// };

// export default SocketProvider;

// type childrenProp = {
//   children: React.ReactNode;
// };

export const socket = io(`${baseURL.socketEndpoint}`, {
    reconnection: true,
    reconnectionDelay: 500,
    transports: ['websocket']
});
export const SocketContext = React.createContext<any>(null);
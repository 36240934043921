import { createSlice, current } from '@reduxjs/toolkit';
import apiInstance from 'apis/api';
import { AppDispatch, dispatch, getState, RootState } from '../store';

export type ItemExtras = {
  _id: string;
  extra: {
    _id: string;
    name: { ar: string; en: string };
    price: number;
    max: number;
    min: number;
  };
  choices: ItemExtrasChoices[];
  rules: string;
};

export type ItemExtrasChoices = {
  _id: string;
  name: { ar: string; en: string };
  price: number;
  choiceItemName_ar?: string;
  choiceItemName_en?: string;
  choiceItemPrice?: string;
};

export type Section = {
  _id: string;
  editable: boolean;
  name: { ar: string; en: string };
  description: string;
  items: MenuItemType[];
};

export type MenuItemType = {
  _id: string;
  name: { ar: string; en: string };
  description: { ar: string; en: string };
  logo: string;
  image: string;
  itemCategories?: string[];
  price: number;
  extras: ItemExtras[];
};

type CurrentItemExtras = {
  [key: string]: ItemExtras[];
};

type MenuState = {
  isLoading: boolean;
  error: boolean;
  sections: Section[];
  branchSections: Section[];
  branchItems: MenuItemType[];
  selectedSection: Section | null;
  selectedItem: MenuItemType | null;
  currentItemExtras: CurrentItemExtras;
  selectedItemExtras: ItemExtras | null;
  branchStatus: { status: string; cancelationReason: string; offPeriod: string };
};

const initialState: MenuState = {
  isLoading: false,
  error: false,
  branchSections: [],
  branchItems: [],
  sections: [],
  selectedSection: null,
  selectedItem: null,
  selectedItemExtras: null,
  currentItemExtras: {},
  branchStatus: {
    status: 'opening',
    cancelationReason: '',
    offPeriod: ''
  }
};

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    stopLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getInitialize(state) {
      const initSection = state.branchSections[0];
      state.isLoading = false;
      state.selectedSection = initSection;
    },

    getSectionsSuccess(state, action) {
      state.isLoading = false;
      state.sections = action.payload;
    },

    addSectionSuccess(state, action) {
      state.isLoading = false;
      state.sections = action.payload;
    },

    deleteSectionSuccess(state, action) {
      state.isLoading = false;
      state.branchSections = action.payload;
    },

    updateSectionSuccess(state, action) {
      state.isLoading = false;
      state.branchSections = action.payload;
    },

    setSelectedSectionSuccess(state, action) {
      state.isLoading = false;
      state.selectedSection = action.payload;
    },

    getItemsSuccess(state, action) {
      state.isLoading = false;
      state.sections = action.payload;
    },

    addItemSuccess(state, action) {
      state.selectedItem = action.payload;
      state.branchItems.push(action.payload);
      state.isLoading = false;
    },

    deleteItemSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    },

    updateItemSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    },

    addItemExtrasSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    },

    addCurrentItemExtrasSuccess(state, action) {
      state.isLoading = false;
      state.currentItemExtras = action.payload;
    },

    updateItemExtrasSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    },

    setSelectedItemExtrasSuccess(state, action) {
      state.isLoading = false;
      state.selectedItemExtras = action.payload;
    },

    setSelectedItemSuccess(state, action) {
      state.isLoading = false;
      state.selectedItem = action.payload;
    },

    updateBranchStatusSuccess(state, action) {
      state.isLoading = false;
      state.branchStatus = action.payload;
    },

    getbranchSectionsSuccess(state, action) {
      state.isLoading = false;
      state.branchSections = action.payload;
    },

    getbranchItemsSuccess(state, action) {
      state.isLoading = false;
      state.branchItems = action.payload;
    }
  }
});

export default slice.reducer;

export function addSection(sectionItem: any) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const { _id } = getState().auth.user.brandId;
      const response = await apiInstance.post(`itemCategories/${_id}`, {
        ...sectionItem
      });
      if (response.data.status) {
        const sections = [...getState().menu.branchSections, response.data.data.itemCategory];

        dispatch(slice.actions.getbranchSectionsSuccess(sections));
        dispatch(slice.actions.setSelectedSectionSuccess(response.data.data.itemCategory));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSection(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.delete(`itemCategories/${id}`);
      if (response.data.status) {
        const sections = getState().menu.branchSections.filter((s) => s._id !== id);
        dispatch(slice.actions.deleteSectionSuccess(sections));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSection(id: any, values: {}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`itemCategories/${id}`, {
        ...values
      });
      if (response.data.status) {
        const filteredSections = [...getState().menu.branchSections].filter((s) => s._id !== id);
        const sections = [...filteredSections, response.data.data.itemCategory].sort(
          (a, b) => a.order - b.order
        );

        dispatch(slice.actions.setSelectedSectionSuccess(response.data.data.itemCategory));
        dispatch(slice.actions.updateSectionSuccess(sections));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedSection(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const currentSection = getState().menu.branchSections.find((s) => s._id === id);
      dispatch(slice.actions.setSelectedSectionSuccess(currentSection));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentBranchItems(brandId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/branches/${brandId}/items`);
      console.log('[[[Branch Menu]]] - Fetching items Result:', response);
      if (response.status) {
        dispatch(slice.actions.getbranchItemsSuccess(response.data.data.items));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItem(sectionId: any, item: any) {
  console.log('yyyyyyyyyyyyyyyyyyyyyyyyyyyyyy', sectionId, item);

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.post(`/sections/${sectionId}/items`, {
        ...item
      });
      if (response.status) {
        console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', response.data.data.item);

        dispatch(slice.actions.addItemSuccess(response.data.data.item));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteItem(itemId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.delete(`items/${itemId}`);
      if (response.data.status) {
        const items = getState().menu.branchItems.filter((s) => s._id !== itemId);
        dispatch(slice.actions.deleteItemSuccess(items));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateItem(sectionId: string, itemId: string, values: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`/sections/${sectionId}/items/${itemId}`, {
        ...values
      });
      if (response.status) {
        const updateItems = [...getState().menu.branchItems];
        const targetItemIndex = updateItems.findIndex((t) => t._id === itemId);
        updateItems[targetItemIndex] = response.data.data.item;
        dispatch(slice.actions.updateItemSuccess(updateItems));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function updateItemsOrder(sourceId: string, destinationId: string) {
//   console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww', sectionId, itemId, values);
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await apiInstance.put(`/sections/${sectionId}/items/${itemId}`, {
//         ...values
//       });
//       if (response.status) {
//         console.log('bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb', response.data.data.item);
//         const updateItems = [...getState().menu.branchItems];
//         const targetItemIndex = updateItems.findIndex((t) => t._id === itemId);
//         updateItems[targetItemIndex] = response.data.data.item;
//         dispatch(slice.actions.updateItemSuccess(updateItems));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

export function getSelectedItem(itemId: string) {
  console.log('[[[Branch Menu]]] - Fetch Data for Selected Item:', itemId);

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/items/${itemId}`);
      if (response.status) {
        dispatch(slice.actions.setSelectedItemSuccess(response.data.data.item));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function addItemExtras(itemId: string, values: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await apiInstance.post(`/items/${itemId}/extras`, {
//         ...values
//       });

//       if (response.status) {
//         const currentItems = [...getState().menu.branchItems];
//         const targetitemIndex = currentItems?.findIndex((t) => t._id === itemId);
//         currentItems[targetitemIndex] = response.data.data.item;

//         dispatch(slice.actions.addItemExtrasSuccess(currentItems));
//         dispatch(slice.actions.setSelectedItemSuccess(response.data.data.item));
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
export function addItemExtras(sectionId: any, values: any) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const currentExtras = { ...getState().menu.currentItemExtras };

      if (currentExtras[sectionId]) {
        currentExtras[sectionId] = [...currentExtras[sectionId], values];
      } else {
        currentExtras[sectionId] = [].concat(values);
      }
      console.log('vvvvvvvvvvvvvvvvvvv', currentExtras);
      dispatch(slice.actions.addCurrentItemExtrasSuccess(currentExtras));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateItemExtras(itemId: any, itemExtraId: any, values: any) {
  console.log(
    '///////////////////////////WWWWWWWWWWWWWWEEEEEEEEEEEEEE',
    itemId,
    itemExtraId,
    values
  );

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put(`/items/${itemId}/extras/${itemExtraId}`, {
        ...values
      });

      if (response.status) {
        const currentItems = [...getState().menu.branchItems];
        const targetitemIndex = currentItems?.findIndex((t) => t._id === itemId);
        currentItems[targetitemIndex] = response.data.data.item;

        dispatch(slice.actions.updateItemExtrasSuccess(currentItems));
        dispatch(slice.actions.setSelectedItemSuccess(response.data.data.item));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteItemExtras(itemId: string, itemExtraId: string) {
  console.log('vv', itemId, itemExtraId);

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.delete(`/items/${itemId}/extras/${itemExtraId}`);

      if (response.status) {
        const currentItems = [...getState().menu.branchItems];
        const targetitemIndex = currentItems?.findIndex((t) => t._id === itemId);
        currentItems[targetitemIndex] = response.data.data.item;

        dispatch(slice.actions.updateItemExtrasSuccess(currentItems));
        dispatch(slice.actions.setSelectedItemSuccess(response.data.data.item));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// Item Extra

export function setSelectedItemExtras(itemExtras: any) {
  console.log('itemExtras', itemExtras);

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const foundedItem = getState().menu.branchItems.find((f) => f._id === itemId);
      // console.log('///00', foundedItem?.extras, itemExtrasId);
      // const filteredItemExtras = foundedItem?.extras.find((e) => e?.extra?._id === itemExtrasId);
      // console.log('///', filteredItemExtras);

      dispatch(slice.actions.setSelectedItemExtrasSuccess(itemExtras));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentBranchSections(branchId: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('[[[Brand Menu]]] - Brand Id:', branchId);
      const response = await apiInstance.get(`/branches/${branchId}/categories`);
      console.log('[[[Branch Menu]]] - Fetching Categories Result:', response);
      if (response.status) {
        dispatch(slice.actions.getbranchSectionsSuccess(response.data.data.itemCategories));
        dispatch(slice.actions.getInitialize());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSelectedItem(id: any, sectionId: any) {
  console.log('[[[Branch Menu]]] - Selected Item Id:', id);

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const currentItem = [...getState().menu.branchItems].find((s) => s._id === id);
      console.log('[[[Branch Menu]]] - Selected Item Details:', currentItem);
      // const currentItem = section?.items.find((s) => s._id === id);
      dispatch(slice.actions.setSelectedItemSuccess(currentItem));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function initMenu() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       // const response = await axios.get('/api/sections');
//       // if (getState().menu.selectedSection === null) {
//       dispatch(slice.actions.getInitialize());
//       // }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

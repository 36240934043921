import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object

const firebaseConfig = {
  apiKey: 'AIzaSyD2O6iT0pbyixteWvMXDuHHNv82ezNf0o0',
  authDomain: 'haader-a9b1b.firebaseapp.com',
  databaseURL: 'https://haader-a9b1b.firebaseio.com',
  projectId: 'haader-a9b1b',
  storageBucket: 'haader-a9b1b.appspot.com',
  messagingSenderId: '959824978921',
  appId: '1:959824978921:web:360d870570b9da09c66178',
  measurementId: 'G-W9YLFVQYEK'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const getFcmToken = () =>
  Notification.requestPermission()
    .then((permission) => getToken(messaging))
    .then((token) => token)
    .catch((error) => {
      console.log('[[[An error occurred while retrieving token]]] - error:', error);
      return '';
    });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const registerPushSW = () => {
  // register service worker
  // if ('serviceWorker' in navigator) {
  //   return window.navigator.serviceWorker
  //     .getRegistration('/firebase-push-notification-scope')
  //     .then((serviceWorker) => {
  //       if (serviceWorker) return serviceWorker;
  //       return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
  //         scope: '/firebase-push-notification-scope',
  //       });
  //     });
  // }
  // throw new Error('The browser doesn`t support service worker.');
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then((registration) => {
        // eslint-disable-next-line no-console
        console.log('[SW]: SCOPE: ', registration.scope);
        return registration.scope;
      })
      .catch((err) => err);
  }
};

import axios from 'axios';
import localStorage from 'redux-persist/es/storage';
import { baseURL } from '../config';

const apiInstance = axios.create({
  baseURL: baseURL.apiEndpoint,
  headers: {
    accept: 'application/json',
    'Content-Type': `application/json`
  }
});

export const unProtectedApiInstance = axios.create({
  baseURL: baseURL.apiEndpoint,
  headers: {
    accept: 'application/json',
    'Content-Type': `application/json`
  }
});

apiInstance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem('accessToken');
    const currentBrand = await localStorage.getItem('auth-auth');
    const { currentBrandId, currentBranchId } = currentBrand && JSON.parse(currentBrand);

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
      config.headers.brand = currentBrandId.replaceAll(/['"]+/g, '');
      config.headers.branch = currentBranchId.replaceAll(/['"]+/g, '');
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiInstance;

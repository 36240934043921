import * as Sentry from "@sentry/react";
import { sentry } from "../config";

export class SentryUtils {
    static initialize() {
        Sentry.init({
          dsn: sentry.dsn,
          environment: sentry.environment,
          integrations: [
            new Sentry.Replay(),
            new Sentry.BrowserTracing({
              // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
              tracePropagationTargets: [
                'localhost',
                /^https:\/\/api-testing\.developyalla\.com\/branch/,
                /^https:\/\/api-staging\.developyalla\.com\/branch/,
                /^https:\/\/api\.haader\.com\/branch/
              ]
            })
          ],
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0,      // Performance Monitoring
          tracesSampleRate: 1.0 // Capture 100% of the transactions
        });          
    }
}

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    menuAvailability: path(ROOTS_DASHBOARD, '/menu/check'),
    menuView: path(ROOTS_DASHBOARD, '/menu/view'),
    orders: path(ROOTS_DASHBOARD, '/orders-overview'),
    orderStatus: path(ROOTS_DASHBOARD, '/order-status'),
    branch: path(ROOTS_DASHBOARD, '/branch'),
    reports: path(ROOTS_DASHBOARD, '/reports'),
    openingTimes: path(ROOTS_DASHBOARD, '/opening-times'),
    recentOrders: path(ROOTS_DASHBOARD, '/orders-history'),
    // performance: path(ROOTS_DASHBOARD, '/recent_orders'),
    item: path(ROOTS_DASHBOARD, '/item')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
};

import { createContext, useContext } from 'react';
import { forgetPassword, login, logout, resetPassword } from 'store/slices/auth';
import { RootState, useDispatch, useSelector } from 'store/store';
// import { AuthContext } from '../contexts/JWTContext';

const useAuth = () => {
  const AuthContext = createContext(null);
  const context = useContext(AuthContext);

  // if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useAuth;

export const useAuthUser = () => {
  const dispatch = useDispatch();
  const {
    user,
    isLoading,
    isAuthenticated,
    resetTokenSent,
    errorMsg,
    currentRolePermissions,
    isSuperAdmin,
    branches,
    currentBranch
  } = useSelector((state: RootState) => state.auth);

  // console.log('[[[From Auth]]] - error value:', errorMsg);
  return {
    user,
    isLoading,
    isAuthenticated,
    resetTokenSent,
    errorMsg,
    currentRolePermissions,
    isSuperAdmin,
    branches,
    currentBranch,

    login: ({ userName, password }: { userName: string; password: string }) =>
      dispatch(
        login({
          userName,
          password
        })
      ),

    logout: () => dispatch(logout()),

    forgetPassword: (email: string) => dispatch(forgetPassword(email)),

    resetPassword: (token: string, passwords: { password: string; confirmPassword: string }) =>
      dispatch(resetPassword(token, passwords))
  };
};

import { createSlice } from '@reduxjs/toolkit';
import apiInstance from 'apis/api';
import { subDays } from 'date-fns';
import { AppDispatch, dispatch, getState } from '../store';
import { DoneCallback } from '../../utils/types';
import { Events, eventsManager } from '../../hooks/useEvent';

export type Order = {
  _id: string;
  name: string;
  description: string;
  price: number;
  createdAt: number;
  status: string;
  currentStatus: string;
  orderNo: string;
  orderDetails: any;
};

export type RejectReason = {
  _id: string;
  status: boolean;
  reason: {
    en: string;
    ar: string;
  };
};

type OrderState = {
  isLoading: boolean;
  isLoadingNewOrders: boolean;
  audioPlay: boolean;
  error: boolean;
  currentOrderdetail: {};
  ordersList: Order[];
  orderStatus: [];
  totalOrders: number;
  totalRevenu: number;
  totalDiscount: number;
  totalDiscounted: number;
  todayOrders: Order[];
  newOrders: Order[];
  currentOrders: Order[];
  upComingOrders: Order[];
  selectedOrder: Order | null;
  rejectReasons: [];
};

const initialState: OrderState = {
  isLoading: false,
  isLoadingNewOrders: false,
  audioPlay: false,
  error: false,
  currentOrderdetail: {},
  ordersList: [],
  orderStatus: [],
  totalOrders: 0,
  totalRevenu: 0.00,
  totalDiscount: 0.00,
  totalDiscounted: 0.00,
  todayOrders: [],
  newOrders: [],
  currentOrders: [],
  upComingOrders: [],
  selectedOrder: null,
  rejectReasons: []
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingNewOrders(state) {
      state.isLoadingNewOrders = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.isLoadingNewOrders = false;
      state.error = action.payload;
    },

    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.ordersList = action.payload.orders;
      if(action.payload.orders.length === 0){
        state.totalOrders = 0;
        state.totalRevenu = 0;
        state.totalDiscount = 0;
        state.totalDiscounted = 0;        
      } else {
        state.totalOrders = action.payload.total;
        state.totalRevenu = action.payload.totalRevenu.toFixed(2);
        state.totalDiscount = action.payload.totalDiscount.toFixed(2);
        state.totalDiscounted = action.payload.totalDiscounted.toFixed(2);
      }
    },
    getOrderStatusSuccess(state, action) {
      // state.isLoading = false;
      state.orderStatus = action.payload;
    },

    getTodayOrdersSuccess(state, action) {
      state.isLoading = false;
      state.todayOrders = action.payload;
    },

    getNewOrdersSuccess(state, action) {
      state.isLoading = false;
      state.isLoadingNewOrders = false;
      state.newOrders = action.payload;
    },

    getUpcomingOrdersSuccess(state, action) {
      state.isLoading = false;
      state.upComingOrders = action.payload;
    },

    getCurrentOrdersSuccess(state, action) {
      state.isLoading = false;
      state.currentOrders = action.payload;
    },

    currentOrderDetailsSuccess(state, action) {
      state.isLoading = false;
      state.currentOrderdetail = action.payload.currentOrderdetail;
    },

    setSelectedOrderSuccess(state, action) {
      state.isLoading = false;
      state.selectedOrder = action.payload;
    },

    getRejectReasonsSuccess(state, action) {
      console.log('[[[Get Reject Reasons]]] - State:', action);
      state.isLoading = false;
      state.rejectReasons = action.payload;
    },
    
    setAudioSuccess(state, action) {
      console.log('[[[[Audio State]]]] - value:', action.payload);
      state.audioPlay = action.payload;
    },
  }
});

export default slice.reducer;

export function getBranchOrders(
  pageNumber: number,
  pageSize: number,
  date?: any,
  status?: string,
  orderNo?: string
) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('[[[Getting Branch Orders History]]]');
      // const branchId: string = getState().auth.currentBranch?._id;
      // get all recent orders for the branch
      const response = await apiInstance.post(`orders/${pageNumber}/${pageSize}`, {
        status,
        fromDate: date?.from || subDays(new Date(), 1),
        toDate: date?.to || new Date(),
        // fromDate: date?.from,
        // toDate: date?.to,
        orderNo,
      });
      if (response) {
        const todayOrders = response.data.data.orders.filter(
          (o: any) => o.createdAt === new Date()
        );
        console.log('[[[Getting Branch Orders History]]] - totals:', response.data.data );

        dispatch(slice.actions.getTodayOrdersSuccess(todayOrders));
        dispatch(slice.actions.getOrdersSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getOrderStatusList() {
  return async (dispatch: any, getState: any) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get('orders-status');
      if (response) {
        console.log('[[[[Order Status List]]]] - Response:', response.data.data.statusList);
        dispatch(slice.actions.getOrderStatusSuccess(response.data.data.statusList));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getNewOrders(options?: { withSound: boolean }) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startLoadingNewOrders());
    try {
      // const branchId: string = getState().auth.currentBranch?._id;
      // [TODO] - change API to the correct one /orders/new
      const response = await apiInstance.get(`orders/new`);
      dispatch(slice.actions.getNewOrdersSuccess(response.data.data.orders));
      if (response.data.data.orders.length && options?.withSound) {
        eventsManager.emit(Events.Audio.Play);
      } else {
        eventsManager.emit(Events.Audio.Pause);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUpcomingOrders() {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      // const branchId: string = getState().auth.currentBranch?._id;
      // const response = await apiInstance.get(`orders/upcoming`);
      // dispatch(slice.actions.getUpcomingOrdersSuccess(response.data.data.orders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCurrentOrders() {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      // const branchId: string = getState().auth.currentBranch?._id;
      // [TODO] - change API to the correct one /orders/current
      console.log('[[[Branch Current Orders]]] - Fetching Data...');
      const response = await apiInstance.get(`orders/current`);
      console.log('[[[Branch Current Orders]]] - Fetching Response:', response);
      dispatch(slice.actions.getCurrentOrdersSuccess(response.data.data.orders));
    } catch (error) {
      console.log('[[[Branch Current Orders]]] - Error:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderDetails(orderId: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await apiInstance.get(`orders/${orderId}/details`);

      if (response.data.status) {
        const currentOrderdetail = response.data.data.order;
        dispatch(slice.actions.currentOrderDetailsSuccess({ currentOrderdetail }));
      } else {
        const error = response.data.message || [...Object.values(response.data?.errors)].join('\n');
        dispatch(slice.actions.hasError({ msg: error }));
      }
    } catch (err: any) {
      const error =
        err.response.data.message || [...Object.values(err.response.data?.errors)].join('\n');
      dispatch(slice.actions.hasError({ msg: error }));
    }
  };
}

export function getRejectReasons() {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('[[[Reject Reasons]]] Invoking API');
      const response = await apiInstance.get(`orders/rejectReasons`);
      console.log('[[[Reject Reasons]]] - API Response:', response);
      dispatch(slice.actions.getRejectReasonsSuccess(response.data.data.rejectReasons));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateCurrentOrderStatus(
  orderId: string,
  key: string,
  rejectReasonId?: string,
  cb?: DoneCallback
) {
  console.log(
    `[[[Update Selected Order Status: API call]]] - Order Id: ${orderId}, Status Key: ${key}`
  );

  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      // which api to consume from backend???
      const response: any = await apiInstance.post(`orders/${orderId}/update-status`, {
        key,
        rejectReasonId: rejectReasonId || ''
      });

      console.log(`[[[Update Selected Order Status: API Response]]] (3) - Result: ${response}`);

      if (key === 'IN_PROGRESS') {
        const updatedNewOrder = getState().orders.newOrders?.filter((o: any) => o._id !== orderId);

        console.log('[[[New Orders List]]] (4) - Not filtered yet:', getState().orders.newOrders);
        console.log(
          `[[[Update New Orders List]]] (5) - filtered by !InProgress: ${updatedNewOrder}`
        );
        dispatch(getNewOrders());
        dispatch(getCurrentOrders());
      }

      if (key === 'REJECTED_BY_BRANCH') {
        // update new orders list with orders except rejected one
        console.log(`[[[Update Selected Order Status]]] Order Rejected`);
        const updatedNewOrder = getState().orders?.newOrders?.filter((o: any) => o._id !== orderId);
        console.log(
          `[[[Update Selected Order Status]]] Update New Orders List: ${updatedNewOrder}`
        );

        dispatch(slice.actions.getNewOrdersSuccess(updatedNewOrder));
      }

      // [TODO] handle ready status
      if (key === 'READY') {
        console.log(`[[[Update Selected Order Status]]] Order Ready`);
        dispatch(getCurrentOrders());
      }

      cb?.(null);
    } catch (err: any) {
      console.log('[[[Order Update Status]]] - Catching Error:', err);
      const error =
        err?.response?.data?.message || [...Object.values(err?.response?.data?.errors)].join('\n');
      dispatch(slice.actions.hasError({ msg: error }));
      cb?.(err, null);
    }
  };
}

export function setSelectedOrder(id: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.get('/api/sections');
      const currentOrder = getState().orders.ordersList.find((s) => s._id === id);
      dispatch(slice.actions.setSelectedOrderSuccess(currentOrder));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCurrentOrdersList(orderId: any) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    try {
      console.log('[[[Updating Current Orders List]]]---------');
      // const currentOrders = getState().orders.currentOrders.find((s) => s._id !== orderId);
      // dispatch(slice.actions.getCurrentOrdersSuccess(currentOrders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function setNewOrders(id: any) {
  console.log('[[[Set New Orders]]] - function invoked');

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('[[[Set New Orders]]] - Order Id:', id);

      // [ASK] get specfic order's details from backend (need calrification)
      const response = await apiInstance.get(`orders/${id}`);
      console.log('[[[Set New Orders]]] - Response:', response.data);

      const newOrder = response.data.data.order;
      // console.log('[[[Set New Orders]]] - New order:', newOrder);

      const currentOrder = [...getState().orders.newOrders, newOrder];
      console.log('[[[Set New Orders]]] - current new orders list:', currentOrder);

      dispatch(slice.actions.getNewOrdersSuccess(currentOrder));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setAudioState(status: any) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.setAudioSuccess(status));
  };
}
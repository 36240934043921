import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@mui/material';
import { dispatch, RootState, useSelector } from 'store/store';
import { styled, useTheme } from '@mui/material/styles';
import { io } from 'socket.io-client';
import { getCurrentBranchData } from 'store/slices/auth';
import { useAuthUser } from '../../hooks/useAuth';
import { baseURL } from '../../config';
import {  SocketContext } from '../../contexts/SocketProvider';


const RootStyle = styled('div')(({ theme }) => ({
  minWidth: '70vw'
}));

const MainStyle = styled('div')({
  display: 'flex',
  flexWrap: 'nowrap',
  gap: '6px',
  fontSize: '16px',
  fontWeight: 'bold',
});

let so: any; // socket

export default function BranchStatusBar() {
  const theme = useTheme();
  const { user }: { user: any } = useAuthUser();
  const { socket } = useContext(SocketContext);

  console.log('[[[[Socket Provided]]]] - result:', socket);
  const { t } = useTranslation();
  
  const [status, setStatus] = useState('opening');
  const { currentBranch } = useSelector((state: RootState) => state.auth);

  // useEffect(() => {
  //   console.log('[[[Socket Server URL]]]:', baseURL.socketEndpoint);
  //   so = io(`${baseURL.socketEndpoint}`);
  // }, []);

  useEffect(() => {
    socket?.on('connect', () => {
      console.log('connected from client, branch:', currentBranch);
    });
    socket?.emit('join-room', { room: currentBranch?._id, clientData: {} });
  }, []);

  useEffect(() => {
    socket.on(`STATUS_UPDATED:${currentBranch?._id}`, (data: any) => {
      console.log('[[[Socket On]]] - Branch Status Updated:', data.branch);
      setStatus(data?.branch?.status?.status);
    });
  }, []);

  useEffect(() => {
    dispatch(getCurrentBranchData());
  }, []);

  useEffect(() => {
    // branch status updated
    if (currentBranch && currentBranch?.status?.status) {
      setStatus(currentBranch?.status?.status);
    }
  }, [currentBranch]);

  return (
    <RootStyle>
      {status === 'opening' && (
        <Alert variant="filled" severity="success">
          <MainStyle>
            <p>{t('branch.branchStatus')} :</p>
            <p>{localStorage.getItem('i18nextLng') === 'ar' ? 'مفتوح' : 'opening'}</p>
          </MainStyle>
        </Alert>
      )}

      {status === 'busy' && (
        <Alert variant="filled" severity="warning">
          <MainStyle>
            <p>{t('branch.branchStatus')} :</p>
            <p>{localStorage.getItem('i18nextLng') === 'ar' ? 'مشغول' : 'busy'}</p>
          </MainStyle>
        </Alert>
      )}

      {status === 'closed' && (
        <Alert variant="filled" severity="error">
          <MainStyle>
            <p>{t('branch.branchStatus')} :</p>
            <p>{localStorage.getItem('i18nextLng') === 'ar' ? 'مغلق' : 'closed'}</p>
          </MainStyle>
        </Alert>
      )}
    </RootStyle>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import apiInstance, { unProtectedApiInstance } from 'apis/api';
import { AppDispatch } from 'store/store';
import { getFcmToken } from 'firebase';
import { AuthState } from '../../types/user';

type IBrand = {
  _id: string;
};

type IUser = {
  _id: string;
  user: {
    phoneVerified: boolean;
    emailVerified: boolean;
    status: boolean;
    _id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatar: string;
    phone: string;
    address: string;
    createdAt: string;
    updatedAt: string;
    branch: string;
    brandId: IBrand;
  };
  createdAt: string;
  updatedAt: string;
};

const initialState: AuthState = {
  isLoading: false,
  isAuthenticated: false,
  resetTokenSent: null,
  isSuperAdmin: false,
  user: null,
  errorMsg: {},
  isSuccess: false,
  currentRolePermissions: {},
  permissionList: [],
  branches: [],
  currentBranch: null,
  currentBranchId: null,
  currentBrandId: null,
  branchStatusList: [],
  busyReasons: [],
  fcmToken: '',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      console.log('[[[Auth Error]]] - ', action.payload);
      state.isLoading = false;
      state.errorMsg = action.payload;
      console.log('[[[Error Set]]] - value:', state.errorMsg);
    },

    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
      state.currentRolePermissions = action.payload.permissions;
      state.isSuccess = true;
    },

    loginSuccess(state, action) {
      console.log('[[[loginSuccess]]]:', action.payload);
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.currentRolePermissions = action.payload.permissions;
      state.isSuccess = true;
      state.errorMsg = {};
    },

    updatedSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    userUpdatedSuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = true;
    },

    markAsSuperAdmin(state, action) {
      state.isSuperAdmin = true;
    },

    currentRolePermissionsSuccess(state, action) {
      // [ASK] - is it working?
      state.currentRolePermissions = action.payload.permissions;
    },

    logoutSuccess(state) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.currentRolePermissions = {};
      state.permissionList = [];
      state.user = null;
      state.errorMsg = {};
      state.currentBranchId = null;
      state.currentBranch = null;
      state.currentBrandId = null;
    },

    updateBranchesSuccess(state, { payload }) {
      console.log('[[[updateBranchesSuccess]]]:', payload);
      const branch = payload;
      state.isLoading = false;
      state.branches = [];
      state.currentBranch = branch;
      state.currentBranchId = branch._id;
      state.currentBrandId = branch.brand;
    },

    setCurrentBranchSuccess(state, { payload }) {
      console.log('[[[setCurrentBranchSuccess]]]:', payload);
      state.isLoading = false;
      state.currentBranch = payload;
      state.currentBranchId = payload._id;
      state.currentBrandId = payload.brand;
    },

    setCurrentBranchOpeningTimes(state, { payload }) {
      state.isLoading = false;
      state.currentBranch = payload;
    },

    setBrandIdSuccess(state, { payload }) {
      console.log('[[[setBrandIdSuccess]]]:', payload);
      state.currentBrandId = payload;
    },

    resetPasswordTokenSentSuccess(state) {
      state.resetTokenSent = null;
    },
    setBranchStatusList(state, action) {
      console.log('[[[Branch Status List]]] - updating redux state:', action);
      state.isLoading = false;
      state.branchStatusList = action.payload;
    },
    setBusyReasons(state, action) {
      console.log('[[[Branch Busy Reasons]]] - updating redux state:', action.payload);
      state.isLoading = false;
      state.busyReasons = action.payload;
    },
    setFcmToken(state, action) {
      state.fcmToken = action.payload;
    },
  }
});

export default slice.reducer;

const setSession = (accessToken: any) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export function login({ userName, password }: { userName: string; password: string }) {
  return async (dispatch: any) => {
    // try {
      console.log('[[[[[Store-Slice-Auth]]]] Login Data:', userName, password);
      const fcmToken = await getFcmToken();
      console.log('[[[[[Store-Slice-Auth]]]] Login - FCM:', fcmToken);
      const response = await unProtectedApiInstance.post('/auth/login', {
        userName,
        password,
        fcmToken
      });

      if (response.status) {
        console.log('[[[[[Store-Slice-Auth]]]] Logged in:', response.status);

        const { user, token } = response.data.data;
        if (user && token) {
          // console.log('[[[[[Store-Slice-Auth]]]] Logged in - User:', user);
          // console.log('[[[[[Store-Slice-Auth]]]] Logged in - Token:', token);
          // console.log('[[[[[Store-Slice-Auth]]]] Logged in - Brand:', user.branchId.brand);

          dispatch(slice.actions.setBrandIdSuccess(user.branchId.brand));
          dispatch(slice.actions.loginSuccess({ user }));
          dispatch(slice.actions.updateBranchesSuccess(user.branchId));
          dispatch(slice.actions.setFcmToken(fcmToken));
        }
        setSession(token);
      }
    // } catch (error: any) {
    //   console.log('[[[Login Error]]] catch:', error);
    //   dispatch(slice.actions.hasError(error.response.data));
    // }
  };
}

export function logout() {
  return async (dispatch: any) => {
    setSession(null);
    dispatch(slice.actions.logoutSuccess());
  };
}

export function removeFcmToken(fcmToken: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('[[[[[Store-Slice-Auth]]]] Logout - FCM:', fcmToken);
      const response = await unProtectedApiInstance.post('/auth/logout', {
        fcmToken,
      });
    } catch (error: any) {
      console.log('[[[Logout Error]]]:', error);
      dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}
export function forgetPassword(email: string) {
  return async (dispatch: any) => {
    const response = await unProtectedApiInstance.post('/password/forget', {
      email
    });
    console.log('reset response', response);
    const { status } = response.data;
    if (status) {
      dispatch(slice.actions.resetPasswordTokenSentSuccess());
    }
  };
}

export function resetPassword(
  token: string,
  { password, confirmPassword }: { password: string; confirmPassword: string }
) {
  return async (dispatch: any) => {
    console.log('reset password', token, password, confirmPassword);
    const response = await unProtectedApiInstance.put(`/password/reset/${token}`, {
      password,
      confirmPassword
    });
    console.log('reset response', response);
    const { status } = response.data;
    if (status) {
      dispatch(slice.actions.resetPasswordTokenSentSuccess());
    }
  };
}

export function updateProfile(values: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.put('/profile/me/update', values);
      if (response.data.status) {
        // dispatch(getInitialize());
        // dispatch(slice.actions.updatedSuccess(response.data.user));
        dispatch(slice.actions.userUpdatedSuccess({}));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function getAllBranches(brandId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/brands/${brandId}/branches`);
      if (response.data.status) {
        dispatch(slice.actions.updateBranchesSuccess(response.data.branches));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function getCurrentBranchData() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/branch/details`);
      console.log('[Branch Data] - API Result:', response.data);

      dispatch(slice.actions.setCurrentBranchSuccess(response.data.data.branch));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBranchStatusList() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('[Branch Status List] - Fetching data ...');

      const response = await apiInstance.get(`/branch/status`);
      console.log('[Branch Status List] - Result:', response.data);

      dispatch(slice.actions.setBranchStatusList(response.data.data.status));
    } catch (error) {
      console.log('[Branch Status List] - Error:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getBusyReasons() {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('[Busy Reasons] - Fetching data ...');

      const response = await apiInstance.get(`/branch/busy/reasons`);
      console.log('[Busy Reasons] - Result:', response.data.data);

      dispatch(slice.actions.setBusyReasons(response.data.data));
    } catch (error) {
      console.log('[Busy Reasons] - Error:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateBranchStatus(values: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const { status, busyReason, offPeriod } = values;
      console.log('[Branch Status Update] - change branch status:', status, busyReason, offPeriod);

      const response = await apiInstance.post(`/branch/update-status`, {
        status,
        time: offPeriod,
        reasonId: busyReason
      });
      console.log('[Branch Status Update] - API Result:', response.data);

      dispatch(slice.actions.setCurrentBranchSuccess(response.data.data.branch));
    } catch (error) {
      console.log('[Branch Status Update] - Error:', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBranchWorKingDays(values: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const { opensAt, closesAt, day, status } = values;
      console.log('[[[Update Opening Times]]] - Per Day:', values);
      const response = await apiInstance.post(`/set-working-hours`, {
        opensAt,
        closesAt,
        day,
        status
      });

      console.log('[[[Update Opening Times]]] API Response:', response.data.data.branch);
      dispatch(slice.actions.setCurrentBranchOpeningTimes(response.data.data.branch));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setItemAvailabilityStatus(values: any) {
  const { id, period, status } = values;
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('[[[Item Availability]]] API - status:', id, status);
      // const response = await apiInstance.put(`/branches/update-item-status`, {
      //   period: 30,
      //   itemId: id,
      //   status
      // });

      const response = await apiInstance.post(`/items/${id}/toggle`, {
        period: 30,
        status
      });
      console.log('[[[Item Availability]]] API - status update result:', response.data.data.branch);

      dispatch(slice.actions.setCurrentBranchSuccess(response.data.data.branch));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setItemAvailabilityPeriod(id: string, period: number, status: boolean) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('[[[Item Availability]]] API - period:', id, period);
      // const response = await apiInstance.put(`/branches/update-item-availabilty`, {
      //   period,
      //   itemId
      // });
      const response = await apiInstance.post(`/items/${id}/toggle`, {
        period,
        status
      });
      console.log('[[[Item Availability]]] API - period update result:', response.data.data.branch);

      dispatch(slice.actions.setCurrentBranchSuccess(response.data.data.branch));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setCurrentBranch(branchId: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const currentBranch = [...getState().auth.branches].find((b) => b._id === branchId);
      dispatch(slice.actions.setCurrentBranchSuccess(currentBranch));
    } catch (error) {
      console.error(error);
    }
  };
}

export function getInitialize() {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiInstance.get(`/profile/me`);
      const { user } = response.data.data;
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: true,
          user
        })
      );
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}

export function setError(error: any) {
  return async (dispatch: any) => {
      dispatch(slice.actions.hasError(error));
  };
}